import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authCode: '',
    authUser: '',
    profile_id: '',
    solution: '',
    unity_id: '',
    successMessage: '',
    errorMessage: '',
    titulo: '',
    address: '',
  },

  getters: {
    getSolution(state){
      return state.solution
    }
  },
  mutations: {
    setSolution(state, data){
       state.solution = data
    }
  },
  actions: {
    sendSolution(context, data) {
      context.commit('setSolution', data);
    }
  },
  modules: {
  }
})
