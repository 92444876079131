import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from '../axios'
import store from '../store/index'

Vue.use(VueRouter)

async function verificationRouter(to, from, next) {
  await axios.get(`/auth/me`).then((response) => {
    store.state.authCode = response.status
    store.state.authUser = response.data
    store.state.profile_id = response.data.profile_id
    store.state.unity_id = response.data.unity_id
  })

  
  if(store.state.authCode == 200) {
    next()
  }

  else {
    next('/')
  }
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../components/dashboard/Dashboard-comp.vue'),
      }
    ],
  },
  {
    path: '/perfis-de-usuarios',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Perfis de Usuários',
        component: () => import('../components/usuarios/TableUnidade-comp.vue'),
      }
    ],
  },
  {
    path: '/permissoes',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Permissões',
        component: () => import('../components/usuarios/PermissionsGrid-comp.vue'),
      }
    ],
  },
  {
    path: '/listar-permissoes',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Permissões',
        component: () => import('../components/usuarios/Permissions-comp.vue'),
      }
    ],
  },
  {
    path: '/unidade-usuarios',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Usuário por Unidade',
        component: () => import('../components/usuarios/CrudUsuarioUnidade-comp.vue'),
      }
    ],
  },
  {
    path: '/doador',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Doadores',
        component: () => import('../components/pacientes/doador/TableDoador-comp.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-doador',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Novo Doador',
        component: () => import('../components/pacientes/doador/CadastrarDoador-comp.vue'),
      }
    ],
  },
  {
    path: '/editar-doador',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Doador Edição',
        component: () => import('../components/pacientes/doador/EditarDoador-comp.vue'),
      }
    ],
  },
  {
    path: '/visualizar-doador',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Doador',
        component: () => import('../components/pacientes/doador/VisualizarDoador-comp.vue'),
      }
    ],
  },
  {
    path: '/receptor',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Receptores',
        component: () => import('../components/pacientes/receptor/Index.vue'),
      }
    ],
  },
  {
    path: '/visualizar-receptor',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Receptores',
        component: () => import('../components/pacientes/receptor/VisualizarReceptor.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-receptor',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Receptores',
        component: () => import('../components/pacientes/receptor/Receptor.vue'),
      }
    ],
  },
  {
    path: '/editar-receptor',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Receptores',
        component: () => import('../components/pacientes/receptor/EditReceptor.vue'),
      }
    ],
  },
  {
    path: '/equipe-medica',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Equipe Médica',
        component: () => import('../components/equipe-medica/Index.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-equipe-medica',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Equipe Médica',
        component: () => import('../components/equipe-medica/Equipe-medica.vue'),
      }
    ],
  },
  {
    path: '/editar-equipe-medica',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Equipe Médica',
        component: () => import('../components/equipe-medica/EditEquipe-medica.vue'),
      }
    ],
  },
  {
    path: '/sorologia',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Sorologia',
        component: () => import('../components/pacientes/doador/sorologia/TableSorologia-comp.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-sorologia',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Sorologia',
        component: () => import('../components/pacientes/doador/sorologia/Sorologia-comp.vue'),
      }
    ],
  },
  {
    path: '/editar-sorologia',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Sorologia',
        component: () => import('../components/pacientes/doador/sorologia/EditarSorologia-comp.vue'),
      }
    ],
  },
  {
    path: '/visualizar-sorologia',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Sorologia',
        component: () => import('../components/pacientes/doador/sorologia/VisualizarSorologia-comp.vue'),
      }
    ],
  },
  {
    path: '/assinatura-digital',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Assinatura Digital',
        component: () => import('../components/assinatura-digital/AssinaturaDigital-comp.vue'),
      }
    ],
  },
  {
    path: '/busca-ativa',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Busca Ativa',
        component: () => import('../components/busca-ativa/BuscaAtivaGrid-comp.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-busca-ativa',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Busca Ativa',
        component: () => import('../components/busca-ativa/BuscaAtiva-comp.vue'),
      }
    ],
  },
  {
    path: '/editar-busca-ativa',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Busca Ativa',
        component: () => import('../components/busca-ativa/EditarBuscaAtiva-comp.vue'),
      }
    ],
  },
  {
    path: '/acompanhar-evolucao',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Busca Ativa',
        component: () => import('../components/busca-ativa/Evolucao-comp.vue'),
      }
    ],
  },
  {
    path: '/exames-de-entrada',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Exames de Entrada',
        component: () => import('../components/pacientes/doador/examesEntrada/ExamesEntrada-comp.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-exames',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Exames de Entrada',
        component: () => import('../components/pacientes/doador/examesEntrada/CadastrarExamesEntrada-comp.vue'),
      }
    ],
  },
  {
    path: '/captacao',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Captação de Orgãos',
        component: () => import('../components/pacientes/doador/captacoes/TableCaptacao-comp.vue'),
      }
    ],
  },
  {
    path: '/cadastrar-captacao',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Captação de Orgãos',
        component: () => import('../components/pacientes/doador/captacoes/SearchDonor-comp.vue'),
      }
    ],
  },
  {
    path: '/editar-captacao',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Captação de Orgãos',
        component: () => import('../components/pacientes/doador/captacoes/EditarCaptacao-comp.vue'),
      }
    ],
  },
  {
    path: '/relatorio-captacao',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Relatório de Captação',
        component: () => import('../components/pacientes/doador/captacoes/RelatorioCaptacao-comp.vue'),
      }
    ],
  },
  {
    path: '/captacao-pcr',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Captação PCR',
        component: () => import('../components/pacientes/doador/captacoes/pcr/CaptacaoPCR-comp.vue'),
      }
    ],
  },
  {
    path: '/disponibilizacao-doador',
    beforeEnter: verificationRouter,
    component: () => import('../views/ViewContainer.vue'),
    children: [
      {
        path: '',
        name: 'Captação PCR',
        component: () => import('../components/pacientes/doador/captacoes/Disponibilizacao-comp.vue'),
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
