export default {
    // DESENVOLVIMENTO
    // apiURL: 'http://desenv.sistransplantes.saude.ba.gov.br/api',
    // captchaURL: 'http://desenv.sistransplantes.saude.ba.gov.br/captcha/api/default',
    // authURL: 'http://desenv.sistransplantes.saude.ba.gov.br/api/auth/me',

    // HOMOLOGAÇÃO
    // apiURL: 'http://sistransplantes.saude.ba.gov.br/api',
    // captchaURL: 'http://homologa.sistransplantes.saude.ba.gov.br/captcha/api/default',
    // authURL: 'http://sistransplantes.saude.ba.gov.br/api/auth/me',

    // lOCALHOST
    // apiURL: 'http://localhost:8000/api',
    // captchaURL: 'http://localhost:8000/captcha/api/default',
    // authURL: 'http://localhost:8000/api/auth/me',

    //PRÉ-PROD
    apiURL: 'https://sistransplantes.saude.ba.gov.br/api',
    captchaURL: 'https://sistransplantes.saude.ba.gov.br/captcha/api/default',
    authURL: 'https://sistransplantes.saude.ba.gov.br/api/auth/me',
}
